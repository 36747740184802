import img1 from '../../assets/images/marques/FRANCK-PROVOST-1.png'
import img2 from '../../assets/images/marques/HQ-1.png'
import img3 from '../../assets/images/marques/JLD.png'
import img4 from '../../assets/images/marques/LOGO-ELITE.png'
import img5 from '../../assets/images/marques/Logo-Miss-Broadway-fond-noir.png'
import img6 from '../../assets/images/marques/PRINCESSE-LILI1.png'
import img7 from '../../assets/images/marques/Xpert-PRO-1.png'
import img8 from '../../assets/images/marques/SAINT-ALGUE.png'
import img9 from '../../assets/images/marques/THE-BARB-XPERT-1.png'

const marqueItems = [
    {
        title: 'FRANCK-PROVOST-1',
        url: img3,
    },
    {
        title: 'HQ-1',
        url: img1,
    },
    {
        title: 'JLD',
        url: img2,
    },
    {
        title: "LOGO-ELITE",
        url: img4,
    },
    {
        title: 'Logo-Miss-Broadway',
        url: img5,
    },
    {
        title: 'PRINCESSE-LILI1',
        url: img6,
    },
    {
        title: 'Xpert-PRO-1',
        url: img7,
    },
    {
        title: 'SAINT-ALGUE',
        url: img8,
    },
    {
        title: 'THE-BARB-XPERT-1',
        url: img9,
    },
]


export { marqueItems }