import img1 from '../../assets/images/slider/0001-scaled.jpg'
import img2 from '../../assets/images/slider/1shoot4brands_BarbXpert_DEF-2-scaled.jpg'
import img3 from '../../assets/images/slider/Elitesite-scaled.jpg'
import img4 from '../../assets/images/slider/Feeling-Wood-ambiance.jpg'
import img5 from '../../assets/images/slider/PHOTO-MB-SITE-3.jpg'
import img6 from '../../assets/images/slider/Titanium-Nature-morte-scaled.jpg'
import img7 from '../../assets/images/slider/anete-lusina-unsplash-valise2.jpg'

const sliderItems = [
    {
        title: 'litesite-scaled',
        url: img3,
    },
    {
        title: '0001-scaled',
        url: img1,
    },
    {
        title: 'BarbXpert',
        url: img2,
    },
    {
        title: "Feeling-Wood-ambiance",
        url: img4,
    },
    {
        title: 'PHOTO-MB-SITE',
        url: img5,
    },
    {
        title: 'Titanium-Nature-morte',
        url: img6,
    },
    {
        title: 'anete-lusina',
        url: img7,
    },
]


export { sliderItems }