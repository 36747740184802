import React from "react";
import { Carousel } from "react-responsive-carousel";
import './sliderStyle.css';

interface SliderProps {
  items: { title: string; url: string }[];
}

const SliderCarousel: React.FC<SliderProps> = ({ items }) => (
  <div className="slider-container">
    <Carousel
      infiniteLoop
      autoPlay
      interval={5000}
      showIndicators
      showThumbs={false}
      transitionTime={600}
      emulateTouch
      showStatus
      showArrows={true}
      renderIndicator={(clickHandler, isSelected, index) => (
        <div
          className={`my-indicator ${isSelected ? 'selected' : ''}`}
          onClick={clickHandler}
          key={index}
        >
          {`0${index + 1}`}
        </div>
      )}
    >
      {items.map((item) => (
        <div key={item.title} className="sliderImg">
          <img alt="" src={item.url} className="sliderImgg" />
        </div>
      ))}
    </Carousel>
  </div>
);

export {SliderCarousel};
