import React, { FC, useState } from 'react';
import {ProductCard} from './ProductCard';
import { produitItems } from '../../data/produits';
import { Modal } from '../modal/ModalContent';
import { ContainerHeader } from '../../modules/ContainerHeader';

const Produits: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="products">
        <div className="container product-container">
          <ContainerHeader className="fw-bold fs-1" title="Nos Produits Populaires" />
          <div className="row">
            {produitItems.map((item) => (
              <ProductCard key={item.title} item={item} openModal={openModal} />
            ))}
          </div>
        </div>
      </div>
      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}
    </>
  );
};

export { Produits };
