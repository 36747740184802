import React from 'react';
// import cvv from '../../../assets/images/checkout/icon/credit-card.png'
// import cardIcon from '../../../assets/images/checkout/icon/credit-card-visa.png'
// import HInput from '../../modules/HInput';
// import SelectBox from '../../modules/SelectBox';
import * as yup from "yup";
import { useFormik } from "formik";
import InputBox from '../../modules/InputBox';
const CreditCardForm: React.FC = () => {

  // const currentYear = new Date().getFullYear();
  // const futureYear = currentYear + 30;
  // const years = [];

  // for (let year = currentYear; year <= futureYear; year++) {
  //   years.push(year.toString());
  // }

  const validationSchema = yup.object({
    cartholder: yup.string().required("Veuillez saisir le titulaire du carte"),
    cartnumber: yup.string().required("Veuillez saisir le numéro de carte"),
    cvv: yup.string().required("Veuillez saisir le cvv"),
    year: yup.string().required("Veuillez saisir le cvv"),
    month: yup.string().required("Veuillez saisir le cvv"),

  });

  const formik = useFormik({
    initialValues: {
      cartholder: "",
      cartnumber: "",
      year: "",
      month: "",
      cvv: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    // handleSubmit,
  } = formik;

  return (
    <div className="shopping-card-checkout-payment mb-3">
      <div className="row d-flex justify-content-center align-items-center text-centerr">
        <span className="text-center mb-4">Informations Du Carte</span>

        <div className="col-12 col-md-12 col-lg-12 mb-3">
          <InputBox
            label="Titulaire de la carte"
            name="cartholder"
            value={values.cartholder}
            type='text'
            className={touched.cartholder && errors.cartholder ? "is-invalid" : ""}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>

        <div className="col-12 col-md-12 col-lg-12 mb-3">
          <InputBox
            label="Numéro de carte"
            name="cartnumber"
            value={values.cartnumber}
            type='text'
            className={touched.cartholder && errors.cartholder ? "is-invalid" : ""}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>

        <div className="row">
          <div className="col-4 col-md-4 col-lg-4 mb-4">
            <InputBox
              label="Mois"
              name="month"
              value={values.month}
              type='text'
              className={touched.month && errors.month ? "is-invalid" : ""}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          <div className="col-4 col-md-4 col-lg-4 mb-4">
            <InputBox
              label="Annee"
              name="year"
              value={values.year}
              type='text'
              className={touched.year && errors.year ? "is-invalid" : ""}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          <div className="col-4 col-md-4 col-lg-4 mb-3">
            <InputBox
              label="cvv"
              name="cvv"
              value={values.cvv}
              type='text'
              className={touched.cvv && errors.cvv ? "is-invalid" : ""}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

          </div>
        </div>

      </div>
    </div>
  );
};

export { CreditCardForm };
