import React from 'react';
import './InputBoxStyle.css'

interface InputBoxProps {
    label?: string;
    name?: string;
    value?: string;
    className?: string;
    type?: string;
    required?: boolean;
    touched?: any;
    errors?: any;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const InputBox: React.FC<InputBoxProps> = ({
    label = "",
    name = "",
    value = "",
    className = "",
    type = "",
    required = false,
    touched = {},
    errors = {},
    handleChange = () => { },
    handleBlur = () => { },
}) => {
    return (
        <div className={`input-box`}>
            <label htmlFor={name} className={`form-label ${value !== '' ? 'label-display' : ''}`}>
                {label}
            </label>

            <input
                type={type}
                name={name}
                className={className}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                required={required}
            />
            <span>{label}</span>

            {touched[name] && errors[name] ? (
                <div className="invalid-feedback">{errors[name]}</div>
            ) : null}
        </div>
    );
};

export default InputBox;
