const categories = [
    {
        name: 'SPA'
        ,
        subcategories: [
            { title: 'ACCESSOIRES', },
            { title: 'ÉPONGES', },
            { title: 'GANTS', }
        ]
    },
    {
        name: 'SOINS'
        ,
        subcategories: [
            { title: 'RAFFERMIR' },
            { title: 'NETTOYER' },
            { title: 'HYDRATER' },
            { title: 'PURIFIER & MATIFIER' },
            { title: 'NOURRIR & REVITALISER' },
            { title: 'APPORTER DE L’ÉCLAT' },
            { title: 'CHEVEUX' },
        ]
    },
    {
        name: 'APPAREILS DE SOINS'
    },
    {
        name: 'ACCESSOIRES DE COIFFURE'
        ,
        subcategories: [
            { title: 'BARETTE - PINCES - CLIPS' },
            { title: 'SERRE TETE - HEANDBAND' },
            { title: 'KIT & SET' },
            { title: 'CISEAUX - PINCEAU COLORATION' },
            { title: 'BROSSE PEIGNE - ACCESSOIRES' },
            { title: 'ORNEMENTS' },
            { title: 'ELASTIQUES - LIENS' },
        ]
    },
    {
        name: 'APPAREILS DE COIFFURE'
        ,
        subcategories: [
            { title: 'LISSEUR' },
            { title: 'CURLERS' },
            { title: 'BROSSE CHAUFFANTE' },
            { title: 'SÈCHE-CHEVEUX' },
        ]
    },
    {
        name: 'MANUCURE-PÉDICURE'
        ,
        subcategories: [
            { title: 'ACCESSOIRES' },
            { title: 'BROSSE - PIERRE PONCE - RAPE' },
            { title: 'CISEAUX - COUPE ONGLES' },
            { title: 'KIT & SET' },
            { title: 'LIME - POLISSOIR - BATONNETS' },
        ]
    },
    {
        name: 'MAKE-UP'
        ,
        subcategories: [
            { title: 'LE TEINT' },
            { title: 'LES YEUX' },
            { title: 'LES LÈVRES' },
            { title: 'LES ONGLES' },
        ]
    },
    {
        name: 'ACCESSOIRES MAKE-UP'
        ,
        subcategories: [
            { title: 'PINCES À ÉPILER' },
            { title: 'PINCEAUX' },
            { title: 'MIROIR' },
            { title: 'ÉPONGES' },
            { title: 'ACCESSOIRES' },
        ]
    },
    {
        name: 'TROUSSES'
    },
    {
        name: 'ENFANTS'
        ,
        subcategories: [
            { title: 'DISNEY' },
            { title: 'FROZEN' },
            { title: 'ACCESSOIRES' },
            { title: 'BROSSES & PEIGNES' },
        ]
    },
    {
        name: 'HOMMES'
        ,
        subcategories: [
            { title: 'ACCESSOIRES' },
            { title: 'APPAREILS DE RASAGE' },
            { title: 'SOINS' },
        ]
    },

]


export { categories }