import React from 'react';
import './modal.css'
import { FiShoppingCart } from 'react-icons/fi'
// import { ProductSlider } from '../slider/ProductSlider';
// import { sliderItems } from '../../data/sliderItems';
// import { produitItems } from '../../data/produits';
import './ModalContent.css'

type ModalProps = {
  show: boolean;
  onClose: () => void;
  product: any;
};

const Modal: React.FC<ModalProps> = ({ show, onClose, product }) => {
  if (!show) return null;

  return (
    <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header text-end">
            {/* <h5 className="modal-title">{product.title}</h5> */}
            <button type="button" className="close btn modal-close-btn" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" >
            <div className="row">
              <div className="col-lg-6">
                <img src={product.url} alt="info" className="img-fluid" />

                {/* <ProductSlider items={produitItems}/> */}
              </div>
              <div className="col-lg-6" style={{ maxHeight: '430px', overflow: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                <h2 className='modal-product-title'>{product.title}</h2>
                <p className='modal-product-prix'>{product.prix}</p>
                <p className='modal-product-desc'>{product.desc}</p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn addtocard-btn">
              <FiShoppingCart className='modal-card-icon'/>Ajouter au panier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Modal };
