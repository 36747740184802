import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar } from '../../components/navbar/Navbar';
import { TopHeader } from '../../components/topHeader/TopHeader';
import { ContainerHeader } from '../../modules/ContainerHeader';
import './loginStyle.css'
import facebook from '../../../assets/images/login/facebook-icon.png'
import * as yup from "yup";
import { useFormik } from "formik";
import { BsFacebook } from 'react-icons/bs';

// import { User } from './user'
import {
  LoginSocialGoogle,
  IResolveParams,
} from 'reactjs-social-login'

import { GoogleLoginButton } from 'react-social-login-buttons'
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import ReactFacebookLogin from 'react-facebook-login';

const REDIRECT_URI = window.location.href;

const Login: React.FC = (props) => {

  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState<any>()

  useEffect(() => {
    if (profile) {
      console.log(profile);
    }
  }, [profile]);

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, [])

  // const onLogoutSuccess = useCallback(() => {
  //   setProfile(null)
  //   setProvider('')
  //   // alert('logout success')
  // }, [])


  const location = useLocation();
  const isLogin = location.pathname === '/login';
  const isRegister = location.pathname === '/register';
  const REACT_APP_GG_APP_ID = '324294602884-jad235obqifn3pm5ecmgnv7h752v89pu.apps.googleusercontent.com';
  // const REACT_APP_GG_APP_ID = '557136801295-tq5g0od7gr59vkibqlucq9gh2b8tc1c1.apps.googleusercontent.com';
  const REACT_APP_FB_APP_ID = '3443858605865162';
  const APP_SECRET = 'ed1777f585b6dbcb7375b670d86c1114';

  const [login, setLogin] = useState(false);
  const [data, setData] = useState<ReactFacebookLoginInfo>();
  const [picture, setPicture] = useState('');

  const [fbClick, setFbClick] = useState(false);

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    console.log(response);
    setData(response);
    setPicture(response.picture?.data?.url || '');
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  const handleClickFacebook = () => {
    setFbClick(true);
  };


  const validationSchema = yup.object({
    email: yup.string().email('Email invalide').required('Veuillez saisir votre adresse e-mail'),
    password: yup.string().required("Veuillez saisir votre mot de passe"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;



  return (
    <>
      <TopHeader />
      <Navbar />
      <ContainerHeader
        className="fw-bold fs-1 mt-5"
        title={
          <>
            <div className="d d-flex text-center justify-content-center">
              <Link className={`nav-link ${isRegister ? 'text-opacity' : ''}`} to="/login">
                Connexion
              </Link>
              <p className="mx-3">/</p>
              <Link className={`nav-link ${isLogin ? 'text-opacity' : ''}`} to="/register">
                S'enregistrer
              </Link>
            </div>
          </>
        }
      />
      <div className="container d-flex justify-content-center align-items-center pt-5">

        <div className="login-form ">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-8">
              <div className={`input-box`}>
                <label
                  htmlFor=""
                  className={`form-label ${values.email !== '' ? 'label-display' : ''}`}>Email</label>

                <input
                  type="text"
                  name="email"
                  className={touched.email && errors.email ? "is-invalid" : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  required
                />
                <span>Email</span>

                {touched.email && errors.email ? (
                  <div className="invalid-feedback ">{errors.email}</div>
                ) : null}

              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8 mt-3">
              <div className={`input-box`}>
                <label
                  htmlFor=""
                  className={`form-label ${values.password !== '' ? 'label-display' : ''}`}>Mot de passe</label>

                <input
                  type="password"
                  name="password"
                  className={touched.password && errors.password ? "is-invalid" : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  required
                />
                <span>Mot de passe</span>

                {touched.password && errors.password ? (
                  <div className="invalid-feedback ">{errors.password}</div>
                ) : null}

              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-8  mt-3 d-flex justify-content-between align-items-center form-check-content">
              <div className="form-ckeck ">
                <input type="checkbox" name="form-control-check" id="" />
                <label htmlFor="" className='ps-2'>SE SOUVENIR DE MOI</label>
              </div>

              <div className="form-ckeck">
                <Link to='' className='nav-link'>MOT DE PASSE PERDU ?</Link>
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-8 mt-4 d-flex justify-content-start align-items-center">
              <button className='btn shopping-card-checkout-total-btn mt-3'>Connexion</button>
            </div>

          </div>

          <hr className='hr-tag' />

          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 text-center mb-4 mt-2">
              Connexion Avec
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">

              {/* <LoginSocialGoogle
                client_id={REACT_APP_GG_APP_ID || ''}
                onLoginStart={onLoginStart}
                redirect_uri={REDIRECT_URI}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                  console.log(profile);
                  console.log(provider);

                }}
                onReject={err => {
                  console.log(err);
                }}
              > */}
              <LoginSocialGoogle
                client_id={REACT_APP_GG_APP_ID || ''}
                onLoginStart={onLoginStart}
                redirect_uri={REDIRECT_URI}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={async ({ provider, data }: IResolveParams) => {
                  try {
                    setProvider(provider);
                    setProfile(data);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                onReject={async (err) => {
                  try {
                    console.log(err);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >

                <GoogleLoginButton className='google-btn login-icon' />
              </LoginSocialGoogle>
              {/* <img src={google} className='login-icon' alt="" onClick={handleClickGoogle} /> */}
              <img src={facebook} className='login-icon ms-3' alt="" onClick={handleClickFacebook} />
              {/* <img src={instagram} className='login-icon ms-1' alt="" /> */}

              {fbClick && !login &&
                <ReactFacebookLogin
                  appId={REACT_APP_FB_APP_ID}
                  autoLoad={true}
                  fields="name,email,picture"
                  scope="public_profile,user_friends"
                  callback={responseFacebook}
                  icon={<BsFacebook />} />
              }

            </div>

          </div>
        </div>

      </div>
    </>
  );
};

export default Login;
