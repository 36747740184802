import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo-1.svg';
import img1 from '../../../assets/images/produits/Boucleur Demeliss - X Curler.jpg';
// import img2 from '../../../assets/images/produits/Brosse Exfoliante Visage.jpg';
import { FiSearch, FiShoppingCart } from 'react-icons/fi';
import { HiOutlineUserCircle } from 'react-icons/hi';
// import { marques } from '../../data/marques';
import { categories } from '../../data/categories';
import { SubShoppingCard } from '../subShoppingCard/SubShoppingCard';
import { MdShoppingCartCheckout } from 'react-icons/md';
import './NavBarStyle.css'
import axios from 'axios';

interface Marque {
  id: number;
  title: string;
}

const Navbar: FC = () => {
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);

  const [marques, setMarques] = useState<Marque[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<Marque[]>('https://127.0.0.1:8000/api/marques.json');
        const data = response.data;
        setMarques(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://127.0.0.1:8000/api/marques.json');
        const data = response.data;
        console.log(data); // Process the data as needed
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleShoppingCartIconClick = () => {
    setShoppingCartBoxVisible(!ShoppingCartBoxVisible);
  };

  const handleSearchIconClick = () => {
    setSearchBoxVisible(!searchBoxVisible);
  };

  return (
    <header className="header-nav w-100 d-flex justify-content-start align-items-center">
      <div className="container top-container">
        <div className="row justify-content-end">
          <div className="col-lg-8 col-md-8 mt-1">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div className="navbar-brand">
                  <Link to="/">
                    <img src={logo} alt="logo" className='hbeauty-logo' />
                  </Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item ps-1">
                      <Link className="nav-link active-link" to="/">Accueil</Link>
                    </li>
                    <li className="nav-item ps-1 dropdown">
                      <a className="nav-link navbar-link dropdown-toggle" href="https://hbeauty.ma/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Catégories
                      </a>

                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {categories.map((item) => (
                          <li key={item.name} className="dropdown-submenu nav-item ps-1 dropdown">
                            <Link className="dropdown-item" to={item.name}>
                              {item.name} {item.subcategories && (<span className='greater text-end'>{'>'}</span>)}
                            </Link>
                            {item.subcategories && (
                              <ul className="dropdown-menu sub-dropdown-menu">
                                {item.subcategories.map((subitem) => (
                                  <li key={subitem.title}>
                                    <Link className="dropdown-item sub-dropdown-item" to="/">
                                      {subitem.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>

                    </li>
                    <li className="nav-item ps-1 dropdown">
                      <a className="nav-link navbar-link  dropdown-toggle" href="https://hbeauty.ma/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Marques
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {marques.map((item) => (
                          <li key={item.id}>
                            <Link className="dropdown-item" to={item.title}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="nav-item ps-1">
                      <Link className="nav-link navbar-link " to="/boutique">Boutique</Link>
                    </li>
                    <li className="nav-item ps-1">
                      <Link className="nav-link navbar-link " to="/contact">Contact</Link>
                    </li>
                    <span className='bar-icon'></span>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div className="col-lg-4 col-md-4 text-end d-flex justify-content-end align-items-center">
            <div className="d-inline-flex text-end mb-1 icon-items">
              <div className="pe-4" onClick={handleShoppingCartIconClick}>
                <FiShoppingCart className="navbar-icon" />
                <span className="position-absolute top-1 start-1s00 translate-middle badge card-badge rounded-circle bg-danger">
                  99
                </span>
              </div>
              <Link className="nav-link pe-4" to="/login">
                <HiOutlineUserCircle className="navbar-icon" />
              </Link>
              <div className="pe-4" onClick={handleSearchIconClick}>
                <FiSearch className="navbar-icon navbar-icon-search" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start shoppingCart box */}
      <div className={`subshopping-card-box ${ShoppingCartBoxVisible ? 'show' : ''}`}>
        <div className="container">
          <div className="row card-content">
            <div className="col-md-12 col-12">

              <SubShoppingCard
                title="Boucleur Demeliss - X Curler"
                quantity='1'
                price="300 DH"
                logo={logo}
              />
              <SubShoppingCard
                title="Boucleur Demeliss - X Curler"
                price="300 DH"
                quantity='1'
                logo={img1}
              />

              <SubShoppingCard
                title="Boucleur Demeliss - X Curler"
                price="300 DH"
                quantity='1'
                logo={img1}
              />

            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-12">
              <div className="total-price text-center d-flex justify-content-between px-4">
                <span className="price-text mt-2">SOUS-TOTAL :</span>
                <span className="price-value mt-2">100 DH</span>
              </div>

              <div className="col-md-12 col-12 text-center">
                <button className='command-btn'>COMMANDER</button>
              </div>

            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12 col-12 text-center card-checkout d-flex justify-content-center align-items-center">
            <Link to='/my-card' className='card-checkout-btn mb-1 nav-link'>
              <MdShoppingCartCheckout className='card-checkout-icon' />VOIR LE PANIER
            </Link>
          </div>
          <div className="subshopping-card-message text-center">
            Livraison Grauite à partir de 400 dh.
          </div>
        </div>
      </div>
      {/* End shoppingCart box */}

      {/* Start Search box */}
      <div className={`search-box ${searchBoxVisible ? 'show' : ''}`}>
        {/* <p className="search-box-title">Qu'est-ce que vous cherchez?</p> */}
        <div className="input-group justify-content-end">
          <input type="text" className="form-controll search-box-input" placeholder="Recherche de produits…" />
          <div className="input-group-append">
            <span className="input-group-textt">
              <FiSearch className="search-box-icon" />
            </span>
          </div>
        </div>
      </div>
      {/* End Search box */}
    </header>
  );
};

export { Navbar };