import React, { FC, useEffect, useState } from 'react';
import { RiInstagramFill } from 'react-icons/ri';
import { FaFacebookF } from 'react-icons/fa';

const Footer: FC = () => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.pageYOffset;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight - 20) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showFooter && (
        <footer className="footer fixed-bottom">
           <div className="footer-container d-flex flex-column align-items-center justify-content-center">
          <div className="row">
            <div className="col-md-12 col-12 text-center ">
              <div className="sociall d-inline-flex">
                <a href='https://www.facebook.com/people/HBeauty/100051215415162/' target='_blank' rel="noreferrer" className='footer-social-icon me-4 ms-5 nav-link'><FaFacebookF className='icon' /></a>
                <a href='https://www.instagram.com/hbeauty_maroc/' target='_blank' rel="noreferrer" className='footer-social-icon nav-link'><RiInstagramFill className='icon' /></a>
              </div>

              <div className="col-md-12 col-12">
                <div className="condition pb-5 pt-5">
                  <span className='condition-util pe-5s'>Conditions générales d'utilisation</span>
                  <span>|</span>
                  <span className='ps-5s'>Conditions Générales de Vente</span>
                </div>
              </div>
              <div className="col-md-12 col-12">
                <span className='copyright'>© HBeauty - Votre Atout Beauté.</span>
              </div>
            </div>
          </div>
        </div>
        </footer>
      )}
    </>
  );
};

export { Footer };