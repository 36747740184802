const filterMarques = [
    {
        selectMarque: [
            { title: 'Elite' },
            { title: 'Franck Provost' },
            { title: 'Xpert Pro' },
            { title: "The Barb'Xpert" },
            { title: 'Miss Broadway' },
            { title: 'HQ' },
            { title: 'Saint Algue' },
            { title: 'Jean-Louis David' },
            { title: 'Princesse Lili' },
            { title: 'Omnia Botanica' },
        ]
    }
]


export { filterMarques }