import React, { FC } from 'react';
import { categorieItems } from '../../data/categorieItems';

const Categories: FC = () => {
  return (
    <>
      <div className='products'>
        <div className="container product-container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-12 mb-5 text-center justify-content-center">
              <div className="d-inline">
                <h2 className='fw-bold'>CATÉGORIE</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {
            categorieItems.map((item) => {
            return <div className="col-md-6 col-lg-4 col-12 category-content" key={item.title}>
              <div className="category-details text-center">
                <a href='https://hbeauty.ma/' className="category-title nav-link mb-2">{item.title}</a>
                <div className="category-img">
                  <a href={`https://hbeauty.ma/${item.title.toLocaleLowerCase().replace(/\s+/g, "-")}`}><img src={item.url} alt={item.title} className='img-fluid' /></a>
                </div>
              </div>
            </div>
            })
            }
          </div>
        </div>
      </div>
    </>
  );
};

export { Categories };
