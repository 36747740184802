/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import { ImPhone } from 'react-icons/im'
import { RiMailOpenFill, RiInstagramFill } from 'react-icons/ri'
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { WindowWidthContext, ContextProps } from '../../context/WindowWidthProvider'

const TopHeader: React.FC = () => {
    const { windowWidth } = useContext<ContextProps>(WindowWidthContext)

    return (
        <div className='top-header-container d-flex justify-content-between align-items-center'>
            <div className="container top-container">
                <div className="row align-items-center">
                    <div className={`col-lg-6 col-md-6 col-12 align-items-center ${windowWidth < 770 ? 'text-center' : 'text-start d-inline-flex ps-4'}`}>
                        <div className="info d-inline-flex">
                            <a className='nav-link me-4'><ImPhone className='icon' /> +212 661 29 75 15</a>
                            <a href='mailto:info@hbeauty.ma' className='nav-link'><RiMailOpenFill className='icon' /> info@hbeauty.ma</a>
                        </div>
                    </div>
                    <div className={`col-lg-6 col-md-6 col-12 align-items-center time ${windowWidth < 770 ? 'text-center' : 'text-end'}`}>
                        <div className="social d-inline-flex">
                            <a href='https://www.facebook.com/people/HBeauty/100051215415162/' target='_blank' rel="noreferrer" className='social-icon me-3 nav-link'><FaFacebookF className='icon' /></a>
                            <a href='https://www.instagram.com/hbeauty_maroc/' target='_blank' rel="noreferrer" className='social-icon me-3 nav-link'><RiInstagramFill className='icon' /></a>
                            <a href='https://hbeauty.ma/' target='_blank' rel="noreferrer" className='social-icon me-3 nav-link'><FaLinkedinIn className='icon' /></a>
                            <a href='https://www.youtube.com/@hbeauty9120' target='_blank' rel="noreferrer" className='social-icon me-3 nav-link'><FaYoutube className='icon' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { TopHeader }
