import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';

type ProductCardProps = {
  item: any;
  openModal: (product: any) => void;
};

const ProductCard: React.FC<ProductCardProps> = ({ item, openModal }) => {
  return (
    <div className="col-md-6 col-lg-3 col-sm-6 col-6 product-item">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-12">
            <div className="product-details text-center">
              <img alt={item.title} src={item.url} className="product-img mb-5" />
              <div className="eye d-flex justify-content-center align-items-center text-center">
                <div className="view">
                  <AiOutlineEye className="eye-icon" onClick={() => openModal(item)} />
                </div>
              </div>
              <div className="product-info">
                <div className="product-title">{item.title}</div>
                <div className="product-price">{item.prix}</div>
                <div className="add-to-card">Ajouter au panier</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {ProductCard};
