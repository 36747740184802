import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../../../assets/images/logo/logo-1.svg'
import './WhatsappWidget.css'

const WhatsappWidget: React.FC = () => {
    return <FloatingWhatsApp
        phoneNumber={'212661297515'}
        accountName={'HBeauty'}
        statusMessage={'online'}
        avatar={logo}
        allowClickAway={true}
        notification={true}
        allowEsc={true}
        placeholder={'Discuter avec nous...'}
        chatMessage={`Bonjour ! 👋

Comment pouvons-nous vous aider ?`}
    />;
};

export { WhatsappWidget };