import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
import { Navbar } from '../../components/navbar/Navbar';
import { TopHeader } from '../../components/topHeader/TopHeader';
import { ContainerHeader } from '../../modules/ContainerHeader';
import { Footer } from '../../components/footer/Footer';
import './ContactStyle.css'
import logo from '../../../assets/images/contact/communicate.png'
import * as yup from "yup";
import { useFormik } from "formik";


const Contact: React.FC = (props) => {

    const validationSchema = yup.object({
        name: yup.string().required("Veuillez saisir votre nom"),
        email: yup.string().email('Email invalide').required('Veuillez saisir votre adresse e-mail'),
        objet: yup.string().required("Veuillez saisir votre objet"),
        message: yup.string().required("Veuillez saisir votre message"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            objet: "",
            message: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log(values)
        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;

    return (
        <>
            <TopHeader />
            <Navbar />
            <ContainerHeader
                className="fw-bold mt-5 contact-title"
                title='CONTACT'
            />

            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-10 col-md-10 col-lg-5">
                    <div className="text-center contact-text">
                        Contactez-nous pour toutes questions supplémentaires, projets possibles et partenariats commerciaux
                    </div>
                </div>

                <div className="col-10 mt-5 d-flex justify-content-center align-items-center">
                    <div className="text-center contact-logo">
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className="contact-info col-10 mt-5 d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <p>Telephone: +212.6.61.29.75.15</p>
                        <p>INFO@HBEAUTY.MA</p>
                    </div>
                </div>
            </div>

            <div className="container contact-container d-flex justify-content-center align-items-center pt-5">

                <form action="" onSubmit={handleSubmit}>
                    <div className="login-form">
                        <div className="row d-flex justify-content-start align-items-center">

                            <div className="col-10 col-md-10 col-lg-10 mb-4">

                                <div className={`input-box`}>
                                    <label
                                        htmlFor=""
                                        className={`form-label ${values.name !== '' ? 'label-display' : ''}`}>Votre Nom</label>

                                    <input
                                        type="text"
                                        name="name"
                                        className={touched.name && errors.name ? "is-invalid" : ""
                                        }
                                        // onFocus={() => setIsnameFocused(true)}
                                        // onClick={() => setIsnameFocused(true)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        required
                                    />
                                    <span>Votre Nom</span>

                                    {touched.name && errors.name ? (
                                        <div className="invalid-feedback ">{errors.name}</div>
                                    ) : null}

                                </div>
                            </div>

                            <div className="col-10 col-md-10 col-lg-10 mb-4">

                                <div className={`input-box`}>
                                    <label
                                        htmlFor=""
                                        className={`form-label ${values.email !== '' ? 'label-display' : ''}`}>Votre adreese de messagerie</label>

                                    <input
                                        type="text"
                                        name="email"
                                        className={touched.email && errors.email ? "is-invalid" : ""
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        required
                                    />
                                    <span>Votre adreese de messagerie</span>

                                    {touched.email && errors.email ? (
                                        <div className="invalid-feedback ">{errors.email}</div>
                                    ) : null}

                                </div>
                            </div>

                            <div className="col-10 col-md-10 col-lg-10 mb-4">

                                <div className={`input-box`}>
                                    <label
                                        htmlFor=""
                                        className={`form-label ${values.objet !== '' ? 'label-display' : ''}`}>Votre Objet</label>

                                    <input
                                        type="text"
                                        name="objet"
                                        className={touched.objet && errors.objet ? "is-invalid" : ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.objet}
                                        required
                                    />
                                    <span>Votre Objet</span>

                                    {touched.objet && errors.objet ? (
                                        <div className="invalid-feedback ">{errors.objet}</div>
                                    ) : null}

                                </div>
                            </div>


                            <div className="col-10 col-md-10 col-lg-10 mb-4">
                                <label
                                    htmlFor=""
                                    className={`form-label`}
                                >
                                    Votre message
                                </label>
                                <textarea
                                    rows={5}
                                    cols={50}
                                    placeholder=""
                                    name="message"
                                    className={`contact-message form-control ${touched.message && errors.message ? "is-invalid" : ""}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.message}
                                    required
                                />
                                {touched.message && errors.message && <div className="invalid-feedback">{errors.message}</div>}
                            </div>

                            <div className="col-10 col-md-10 col-lg-10 mt-2 d-flex justify-content-start align-items-center">
                                <button className='btn shopping-card-checkout-total-btn mt-3'>Envoyer</button>
                            </div>

                        </div>

                    </div>
                </form>

            </div>

            <Footer/>
        </>
    );
};

export { Contact };
