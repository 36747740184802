import React, { FC } from 'react';
import { marqueItems } from '../../data/marqueItems';
import './marqueStyle.css';
import {SlickCarousel} from '../slickCarousel/SlickCarousel';

const Marques: FC = () => {
  return (
    <div className="marques">
      <div className="product-container container">
        <div className="row d-flex justify-content-center align-items-center align-items-center">
          <div className="col-md-12 col-lg-12 text-center justify-content-center">
            <div className="d-inline">
              <h2 className="fw-bold">Nos Marques</h2>
              <p>Découvrez les produits de nos marques partenaires</p>
            </div>
          </div>
          <div className="row">
            <SlickCarousel items={marqueItems} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Marques };
