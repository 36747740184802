import React, { FC } from 'react';

interface ContainerHeaderProps {
  title: any;
  className: string;
}

const ContainerHeader: FC<ContainerHeaderProps> = ({ title, className }) => {
  return (
    <div className="row align-items-center">
      <div className="col-md-12 col-lg-12 mb-5 text-center justify-content-center">
        <div className="d-inline">
          <h2 className={className}>{title}</h2>
        </div>
      </div>
    </div>
  );
};

export { ContainerHeader };
