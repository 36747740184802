import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from '../components/home/Home';
import { ShoppingCard } from '../components/shoppingCard/ShoppingCard';
import Login from '../pages/Login/login';
import Register from '../pages/Login/register';
import { Contact } from '../pages/contact/Contact';
import { Boutique } from '../pages/boutique/Boutique';
import { ProductDetails } from '../pages/productDetails/ProductDetails';

const Routing: React.FC = () => {

  return (
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-card" element={<ShoppingCard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/boutique" element={<Boutique />} />
          <Route path="/product" element={<ProductDetails />} />
        </Routes>
      </Router>
  );
};

export {Routing};
