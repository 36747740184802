import img1 from '../../assets/images/produits/Boucleur Demeliss - X Curler.jpg';
import img2 from '../../assets/images/produits/Brosse Exfoliante Visage.jpg';
import img3 from '../../assets/images/produits/Brosse lissante Demeliss - PRO.png';
import img4 from '../../assets/images/produits/Cellroller Stimulateur de Collagène.jpg';

const produitItems = [
    {
        id: 1,
        title: 'Boucleur Demeliss - X Curler',
        url: img1,
        prix: '799 DH',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiamLorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo aperiam',
        images: [
            {imgUrl : img1},
            {imgUrl : img2},
            {imgUrl : img3},
            {imgUrl : img4},
        ],
    },
    {
        id: 2,
        title: 'Brosse Exfoliante Visage',
        url: img2,
        prix: '369 DH',
        images: [
            {imgUrl : img2},
            {imgUrl : img2},
            {imgUrl : img2},
            {imgUrl : img2},
        ],
    },
    {
        id: 3,
        title: 'Brosse lissante Demeliss - PRO',
        url: img3,
        prix: '799 DH',
        images: [
            {imgUrl : img3},
            {imgUrl : img3},
            {imgUrl : img3},
            {imgUrl : img3},
        ],
    },
    {
        id: 4,
        title: 'Cellroller Stimulateur de Collagène',
        url: img4,
        prix: '389 DH',
        images: [
            {imgUrl : img4},
            {imgUrl : img4},
            {imgUrl : img4},
            {imgUrl : img4},
        ],
    },
];

export { produitItems };
