import React from 'react'
import { TopHeader } from '../topHeader/TopHeader';
import { Navbar } from '../navbar/Navbar';
import {SliderCarousel} from '../slider/SliderCarousel';
import { Marques } from '../marques/Marques';
import { Produits } from '../produits/Produits';
import { Categories } from '../categorie/Categories';
import { Footer } from '../footer/Footer';
import { sliderItems } from "../../data/sliderItems";

const Home: React.FC = () => {

    return (
        <>
            <TopHeader />
            <Navbar />
            <SliderCarousel items={sliderItems}/>
            <Marques />
            <Produits />
            <Categories />
            <Footer />
        </>
    )
}

export { Home }
