import React, { useState } from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { TopHeader } from '../../components/topHeader/TopHeader';
import './ProductDetailsStyle.css'
import { FiShoppingCart } from 'react-icons/fi';
import { HiMinusSm } from 'react-icons/hi';
import { BiPlus } from 'react-icons/bi';
import { produitItems } from '../../data/produits';
// import ImageGallery from 'react-image-gallery';
import ReactImageGallery from 'react-image-gallery';
import ProductGallery from '../../components/Gallery/ProductGallery';

const ProductDetails: React.FC = () => {

    const [cardItem, setCardItem] = useState(1);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [productId, setProductId] = useState(1);



    const increment = () => {
        setCardItem(prevItem => prevItem + 1);
    };

    const decrement = () => {
        if (cardItem > 0) {
            setCardItem(prevItem => prevItem - 1);
        }
    };

    const updateMainImage = (id: any) => {
        setCurrentImageIndex(id)
            ;
    };

    // Find the product by its ID
    const product = produitItems.find(item => item.id === productId);

    // Create an array of images for the gallery
    const images = product
        ? product.images.map(image => ({
            original: image.imgUrl,
            thumbnail: image.imgUrl,
        }))
        : [];

    if (!product) {
        return <div>Product not found.</div>;
    }

    //     const images = produitItems.flatMap((item) =>
    //     item.images.map((img) => ({
    //       original: img.imgUrl,
    //       thumbnail: img.imgUrl,
    //     }))
    //   );




    return (
        <>
            <TopHeader />
            <Navbar />

            <div className="product-details mt-5 pt-5 mb-5">
                <div className="product-constainerr container d-flex justify-content-center ">
                    <div className="row d-flex justify-content-center">

                        <div className="col-xl-7 col-lg-7 col-md-7 col-12 mt-2 mb-5">
                            <ProductGallery productId={productId} />
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-12 mt-2">
                            <div className="product-details-info">
                                <h1 className='product-details-title'>Lisseur Demeliss – ONE</h1>
                                <p className='product-details-prix mt-3 mb-5'>1200.00 DH</p>
                                <p className='product-details-desc'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa voluptate quod ducimus odit quam molestiae vero placeat nostrum eius necessitatibus, voluptates sequi eum delectus vitae quae aut alias distinctio repudiandae maxime molestias expedita. Laborum harum perferendis repellat tenetur nesciunt sed.</p>
                            </div>

                            <hr className='hr-tag' />

                            <div className="product-details-total">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-6">
                                        Prix total :
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-6">
                                        1200 DH
                                    </div>
                                </div>
                            </div>

                            {/* <hr className='hr-tag' /> */}

                            <div className="row align-items-center mt-5">
                                <div className="col-md-3 col-lg-3">
                                    <div className="shopping-card-item-quantity align-items-center text-center">
                                        <span className="align-items-center fs-4" onClick={decrement}>
                                            <HiMinusSm className='shopping-card-item-quantity-icon' />
                                        </span>
                                        <span className="align-items-center px-2">{cardItem}</span>
                                        <span className="align-items-center fs-4" onClick={increment}>
                                            <BiPlus className='shopping-card-item-quantity-icon' />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <button type="button" className="btn addtocard-btn">
                                        <FiShoppingCart className='modal-card-icon' />Ajouter au panier
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export { ProductDetails };
