import React, { memo, useState } from 'react';
import { Navbar } from '../navbar/Navbar';
import { TopHeader } from '../topHeader/TopHeader';
import { ContainerHeader } from '../../modules/ContainerHeader';
import logo from '../../../assets/images/produits/Brosse lissante Demeliss - PRO.png'
import checkoutImage from '../../../assets/images/checkout/promo-2-removebg-preview.png'
import checkoutImage1 from '../../../assets/images/checkout/empty-card-2.webp'
import mastercard from '../../../assets/images/checkout/master.png'
import visacard from '../../../assets/images/checkout/visa.png'
import cash from '../../../assets/images/checkout/cash.png'
import './ShoppingCard.css';
import { ShoppingCardItem } from './ShoppingCardItem';
import { CreditCardForm } from '../creditCardForm/CreditCardForm';
import InputBox from '../../modules/InputBox';
import * as yup from "yup";
import { useFormik } from "formik";

const ShoppingCard: React.FC = () => {
    const [cardItem, setCardItem] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('livraison');
    const [expeditionOption, setExpeditionOption] = useState('forfait');

    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(event.target.value);
    };

    const handleExpeditionOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpeditionOption(event.target.value);
    };

    const validationSchema = yup.object({
        codepromo: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            codepromo: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log(values)
        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;


    return (
        <>
            <TopHeader />
            <Navbar />

            <div className="shopping-card d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    <ContainerHeader className='fw-bold fs-1' title="Mon panier" />
                    {
                        cardItem === 1 ?
                            <>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center align-items-center col-">
                                        <img src={checkoutImage1} alt="" className='shopping-card-empty-image' />
                                    </div>
                                    <div className="col-12 mt-3 d-flex justify-content-center align-items-center col-">
                                        <h3>Votre panier est actuellement vide.</h3>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="row">

                                        <div className="col-md-12 col-lg-7 shopping-card-items">
                                            <div className="items">
                                                <ShoppingCardItem
                                                    title="Boucleur Demeliss - X Curler"
                                                    quantity='1'
                                                    price="300 DH"
                                                    totlalPrice="600 DH"
                                                    logo={logo}
                                                />
                                                <ShoppingCardItem
                                                    title="Boucleur Demeliss - X Curler"
                                                    quantity='1'
                                                    price="300 DH"
                                                    totlalPrice="600 DH"
                                                    logo={logo}
                                                />
                                                <ShoppingCardItem
                                                    title="Boucleur Demeliss - X Curler"
                                                    quantity='1'
                                                    price="300 DH"
                                                    totlalPrice="600 DH"
                                                    logo={logo}
                                                />
                                                <ShoppingCardItem
                                                    title="Boucleur Demeliss - X Curler"
                                                    quantity='1'
                                                    price="300 DH"
                                                    totlalPrice="600 DH"
                                                    logo={logo}
                                                />

                                            </div>

                                            <div className="promo">
                                                <div className="code-promo d-flex justify-content-between align-items-center">
                                                    <div className="code-promo-value d-flex ms-1 align-items-center">
                                                        <div className="code-promo-image">
                                                            <img src={checkoutImage} alt="" />
                                                        </div>
                                                        <div className="code-promo-input ms-2">
                                                            <InputBox
                                                                label="Code promo"
                                                                name="codepromo"
                                                                value={values.codepromo}
                                                                type='text'
                                                                className={touched.codepromo && errors.codepromo ? "is-invalid" : ""}
                                                                touched={touched}
                                                                errors={errors}
                                                                handleChange={handleChange}
                                                                handleBlur={handleBlur}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="code-promo-btn">
                                                        <button className='btn'>APPLIQUER LE CODE PROMO</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-5">
                                            <div className="shopping-card-checkout">
                                                <div className="row">

                                                    <div className="shopping-card-checkout-title">
                                                        <span>TOTAL PANIER</span>
                                                        <hr className='hr-tag' />
                                                    </div>

                                                    <div className="shopping-card-checkout-soustotal d-flex justify-content-between px-5 mt-2 mb-3">
                                                        <div className="shopping-card-checkout-soustotal-title">SOUS-TOTAL</div>
                                                        <div className="shopping-card-checkout-soustotal-value">1200.00 DH</div>
                                                    </div>

                                                    <div className="shopping-card-checkout-expedition d-flexx justify-content-betweenn px-5 mt-2 mb-3">
                                                        <div className="row">

                                                            <div className="col-5 col-lg-5 col-md-12">
                                                                <div className="shopping-card-checkout-expedition-title mb-2">
                                                                    <span>EXPÉDITION</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-7 col-lg-7 col-md-12">
                                                                <div className="form-check">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="expedition"
                                                                            id="forfait"
                                                                            value="forfait"
                                                                            checked={expeditionOption === 'forfait'}
                                                                            onChange={handleExpeditionOptionChange}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="forfait">
                                                                            FORFAIT: 50.00 DH
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="expedition"
                                                                        id="gratuit"
                                                                        value="gratuit"
                                                                        checked={expeditionOption === 'gratuit'}
                                                                        onChange={handleExpeditionOptionChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="gratuit">
                                                                        LIVRAISON GRATUITE
                                                                    </label>
                                                                </div>

                                                                <div className="row shopping-card-checkout-expedition-note mt-3">
                                                                    <span>Les options de livraison seront mises à jour lors de la commande.</span>
                                                                </div>

                                                                <div className="row shopping-card-checkout-expedition-btn mt-3">
                                                                    <button className='btn'>CALCULER LES FRAIS D'EXPÉDITION.</button>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    <hr className='hr-tag' />

                                                    <div className="shopping-card-checkout-payment mb-3">
                                                        <div className="row d-flex justify-content-center align-items-center text-center">
                                                            <span className='text-center mb-4'>Methode de payment</span>
                                                            <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-fDlex">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="payment"
                                                                        id="livraison"
                                                                        value="livraison"
                                                                        checked={paymentMethod === 'livraison'}
                                                                        onChange={handlePaymentMethodChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="livraison">
                                                                        À la livraison
                                                                    </label>
                                                                </div>
                                                                <label htmlFor="livraison">
                                                                    <img src={cash} alt="" className='shopping-card-checkout-payment-mastercard' />
                                                                </label>
                                                            </div>
                                                            <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-flqex">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="payment"
                                                                        id="carte"
                                                                        value="carte"
                                                                        checked={paymentMethod === 'carte'}
                                                                        onChange={handlePaymentMethodChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="carte">
                                                                        Carte bancaire
                                                                    </label>
                                                                </div>
                                                                <label htmlFor="carte">
                                                                    <img src={mastercard} alt="" className='shopping-card-checkout-payment-mastercard' />
                                                                    <img src={visacard} alt="" className='shopping-card-checkout-payment-mastercard' />
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='hr-tag' />

                                                    {paymentMethod === 'carte' && (
                                                        <CreditCardForm />
                                                    )}

                                                    <hr className='hr-tag' />

                                                    <div className="row">
                                                        <div className="shopping-card-checkout-total d-flex justify-content-between px-5 mb-5">
                                                            <div className="shopping-card-checkout-total-title px-">TOTAL</div>
                                                            <div className="shopping-card-checkout-total-value">1200.00 DH</div>

                                                        </div>
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-8 text-center">
                                                                <button type='submit' className='shopping-card-checkout-total-btn btn'>Valider la commande</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                    }

                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

const MemoizedShoppingCard = memo(ShoppingCard);

export { MemoizedShoppingCard as ShoppingCard };
