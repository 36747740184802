import React, { memo, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { GrFormClose } from 'react-icons/gr';
import { BiPlus } from 'react-icons/bi';
import { HiOutlineMinusSm,HiMinusSm } from 'react-icons/hi';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

interface ShoppingCardItemProps {
  title: string;
  price: string;
  totlalPrice: string;
  quantity: string;
  logo: string;
}

const ShoppingCardItem: React.FC<ShoppingCardItemProps> = ({
  title,
  price,
  logo,
  totlalPrice,
  quantity,
}) => {
  const [cardItem, setCardItem] = useState<number>(parseInt(quantity));

  const increment = () => {
    setCardItem(prevItem => prevItem + 1);
  };

  const decrement = () => {
    if (cardItem > 0) {
      setCardItem(prevItem => prevItem - 1);
    }
  };

  return (
    <>
      <div className="shopping-card-item d-flex justify-content-betdween align-items-center">
        <div className="container">
          <div className="row">
            {/* Part - 1 */}
            <div className="col-2">
              <img src={logo} alt="" className="shopping-card-item-img" />
            </div>

            <div className="col-5 d-flex align-items-center">
              <div className="shopping-card-item-details">
                <div className="shopping-card-item-title">{title}</div>
                <div className="subshopping-card-item-prix">{price}</div>
              </div>
            </div>

            <div className="col-2 d-flex align-items-center">
              <div className="shopping-card-item-quantity align-items-center text-center">
                <span className="align-items-center fs-4" onClick={decrement}>
                  <HiMinusSm className='shopping-card-item-quantity-icon' />
                </span>
                <span className="align-items-center px-2">{cardItem}</span>
                <span className="align-items-center fs-4" onClick={increment}>
                  <BiPlus className='shopping-card-item-quantity-icon' />
                </span>
              </div>
            </div>

            <div className="col-2 d-flex align-items-center">
              <div className="shopping-card-item-total align-items-center">
                <span className="align-items-center px-2">{totlalPrice}</span>
              </div>
            </div>

            <div className="col-1 d-flex align-items-center">
              <div className="trash-icon">
                <BsFillTrashFill className="trash" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className='hr-tag' /> */}
    </>
  );
};

const MemoizedShoppingCardItem = memo(ShoppingCardItem);

export { MemoizedShoppingCardItem as ShoppingCardItem };
