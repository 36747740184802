import React from 'react';
import ImageGallery from 'react-image-gallery';
import { produitItems } from '../../data/produits';
// import "react-image-gallery/styles/css/image-gallery.css";

import './style.css'


interface ProductGalleryProps {
  productId: number;
}

const ProductGallery: React.FC<ProductGalleryProps> = ({ productId }) => {
  // Find the product by its ID
  const product = produitItems.find(item => item.id === productId);

  // Create an array of images for the gallery
  const images = product
    ? product.images.map(image => ({
      original: image.imgUrl,
      thumbnail: image.imgUrl,
    }))
    : [];

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <div>
      <ImageGallery
        items={images}
        lazyLoad={true}
        // thumbnailPosition="left"
      />
    </div>
  );
};

export default ProductGallery;
