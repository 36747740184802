import React from 'react';
import './App.css';
import { Routing } from './app/routing/Routing';
import { BackToTop } from './app/components/backToTop/BackToTop';
import { WhatsappWidget } from './app/components/whatsappWidget/WhatsappWidget';
function App() {
  return (
    <>
      <Routing />
      <BackToTop />
      <WhatsappWidget />
    </>

  );
}

export default App;
