import img1 from '../../assets/images/categories/1.jpg'
import img2 from '../../assets/images/categories/2.png'
import img3 from '../../assets/images/categories/3.png'
import img4 from '../../assets/images/categories/4.jpg'
// import img5 from '../../assets/images/categories/5.png'
import img5 from '../../assets/images/categories/5.jpg'
import img6 from '../../assets/images/categories/6.jpg'


const categorieItems = [
    {
        title: 'ACCESSOIRES DE COIFFURE',
        url: img1,
    },
    {
        title: 'ACCESSOIRES MAKE UP',
        url: img2,
    },
    {
        title: 'APPAREILS DE COIFFURE',
        url: img3,
    },
    {
        title: "APPAREILS DE SOIN",
        url: img4,
    },
    {
        title: "ENFANTS",
        url: img5,
    },
    {
        title: "HOMMES",
        url: img6,
    },
]


export { categorieItems }