import React, { FC } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type SliderProps = {
  items: { title: string; url: string }[];
};

const SlickCarousel: FC<SliderProps> = ({ items }) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 200,
    cssEase: "linear",
    initialSlide: Math.floor(items.length / 2),
  };

  return (
    <Slider {...settings}>
      {items.map((item) => (
        <div key={item.title} className="slick-content">
          <img src={item.url} alt={item.title} className="slick-img" />
        </div>
      ))}
    </Slider>
  );
};

const MemoizedSlickCarousel = React.memo(SlickCarousel);

export { MemoizedSlickCarousel as SlickCarousel };
