const filtertWith = [
    {
        tripar: [
            { title: 'défaut', },
            { title: 'popularity', },
            { title: 'notes moyennes', },
            { title: 'plus récent au plus ancien', },
            { title: 'tarif croissant', },
            { title: 'tarif décroissant', },
    
        ]
    },

]


export { filtertWith }