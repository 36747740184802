import React, { useState } from 'react';
import { Navbar } from '../../components/navbar/Navbar';
import { TopHeader } from '../../components/topHeader/TopHeader';
import { ProductCard } from '../../components/produits/ProductCard';
import { boutiqueItems } from './boutiqueItems';
import { Modal } from '../../components/modal/ModalContent';
import { categories } from '../../data/categories';
import { Link } from 'react-router-dom';
import { ContainerHeader } from '../../modules/ContainerHeader';
import './BoutiqueStyle.css'
import Slider from '@mui/material/Slider';
import { HiOutlineMinusSm } from 'react-icons/hi'
import { filtertWith } from '../../data/filter';
import { filterMarques } from '../../data/filterMarque';

function valuetext(value: number) {
  return `${value}`;
}

const Boutique: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [price, setPrice] = React.useState<number[]>([150, 850]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPrice(newValue as number[]);
  };

  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <>
      <TopHeader />
      <Navbar />
      <ContainerHeader className="fw-bold mt-5 mb-5 contact-title" title="BOUTIQUE" />

      <div className="boutique">
        <div className="product-constainere containerr filter- container d-flex justify-content-center">
          <div className="row d-flex justify-content-center">
            <div className="col-11 col-lg-3 col-md-3 mt-2 mb-4 pb-4 filter">
              <div className="container mt-5">

                {/* Start Result Filter */}
                <div className="row">
                  <div className="filter-title filter-tri">
                    <span>filtrer le résultat</span>
                    <div className="fil">
                      <div className="accordion" id="accordionExample">
                        {filtertWith.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#tricollapse${index}`}
                                aria-expanded="false"
                                aria-controls={`tricollapse${index}`}
                              >
                                Tri Par
                              </button>
                            </h2>
                            <div
                              id={`tricollapse${index}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {item.tripar && (
                                  <ul className="list-unstyled">
                                    {item.tripar.map((subitem, subindex) => (
                                      <li key={subindex}>
                                        <Link className='nav-link'
                                          to={`/?orderby=${subitem.title.toLowerCase().replace('-', '').replace(/\s+/g, '-')}`}>
                                          {subitem.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>

                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr-tag" />
                {/* End Result Filter */}


                {/* Start Categories Filter */}
                <div className="row">

                  <div className="filter-title filter-categories">
                    CATÉGORIES
                    <div className="fil">
                      <div className="accordion" id="accordionExample">
                        {categories.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                              >
                                {item.name}
                              </button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {item.subcategories && (
                                  <ul className="list-unstyled">
                                    {item.subcategories.map((subitem, subindex) => (
                                      <li key={subindex}>
                                        <Link className='nav-link'
                                          to={`/${item.name.toLowerCase().replace('-', '').replace(/\s+/g, '-')}/${subitem.title.toLowerCase().replace('-', '').replace(/\s+/g, '-')}`}>
                                          {subitem.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>

                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                </div>
                <hr className='hr-tag' />
                {/* End Categories Filter */}


                {/* Start Marques Filter */}
                <div className="row">
                  <div className="filter-title filter-tri">
                    <span>MARQUES</span>
                    <div className="fil">
                      <div className="accordion" id="accordionExample">
                        {filterMarques.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#marquecollapse${index}`}
                                aria-expanded="false"
                                aria-controls={`marquecollapse${index}`}
                              >
                                Choisir une marque
                              </button>
                            </h2>
                            <div
                              id={`marquecollapse${index}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {item.selectMarque && (
                                  <ul className="list-unstyled">
                                    {item.selectMarque.map((subitem, subindex) => (
                                      <li key={subindex}>
                                        <Link className='nav-link'
                                          to={`/marque/${subitem.title.toLowerCase().replace('-', '').replace(/\s+/g, '-')}`}>
                                          {subitem.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>

                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='hr-tag' />
                {/* End Marques Filter */}


                {/* Start Price Filter */}
                <div className="row">
                  <div className="filter-title filter-price">
                    FILTRER PAR TARIF
                  </div>
                  <div className="price-filter mt-4">
                    <div className="row">
                      <div className="col-12">
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={price}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          max={1500}
                          min={0}
                        />
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-8">
                            <div className="price-values">
                              <span className='filter-price-value'>PRIX : {price[0]} DH <HiOutlineMinusSm /> {price[1]} DH</span>
                            </div>
                          </div>
                          <div className="col-3">
                            <button className='btn filter-price-btn'>Filter</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Marques Filter */}


              </div>

            </div>
            <div className="col-12 col-lg-9 col-md-9 d-flex flex-wrap">
              {boutiqueItems.map((item, index) => (
                <ProductCard key={index} item={item} openModal={openModal} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}
    </>
  );
};

export { Boutique };
