import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {WindowWidthProvider} from './app/context/WindowWidthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WindowWidthProvider>
      <App />
    </WindowWidthProvider>

  </React.StrictMode>
);

reportWebVitals();
