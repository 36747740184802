import React, { memo } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { GrFormClose } from 'react-icons/gr';

interface SubShoppingCardProps {
  title: string;
  price: string;
  quantity: string;
  logo: string;
}

const SubShoppingCard: React.FC<SubShoppingCardProps> = ({ title, price, logo, quantity }) => (
  <div className="subshopping-card-item d-flex justify-content-betdween align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-3 col-3">
        <img src={logo} alt="" className="subshopping-card-item-img" />
        </div>
        <div className="col-md-8 col-lg-8 col-sm-8 col-8">
          <div className="subshopping-card-item-details">
            <div className="subshopping-card-item-title align-items-center">{quantity} <GrFormClose className='subshopping-card-close-icon'/> {title}</div>
            <div className="subshopping-card-item-prix">{price}</div>
          </div>
        </div>
        <div className="col-md-1 col-lg-1 col-sm-1 col-1 d-flex align-items-center">
          <div className="trash-icon">
            <BsFillTrashFill className="trash" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const MemoizedSubShoppingCard = memo(SubShoppingCard);

export { MemoizedSubShoppingCard as SubShoppingCard };
